.container {
  margin-top: var(--spacing-top); /* Dev default as part of margin refactor */
  margin-bottom: var(--spacing-bottom);
  background-color: var(--page-theme-background-color, var(--color-bg-shade));
  padding-inline: var(--spacing-sides);
}

.inner {
  margin-inline: auto;
  max-width: var(--max-width-base);
}

.title {
  display: none;
  margin-bottom: 52px;
  color: var(--page-theme-contrast-color);
  font-weight: var(--font-weight-heading);
  font-size: var(--font-size-36px);
  font-family: var(--font-family-heading);

  @media (width >= 1000px) {
    display: block;
  }
}

.support-module-artwork--padding {
  padding: 0;
}

.support-modules {
  display: flex;
  row-gap: 30px;
  flex-direction: column;

  @media (width >= 650px) {
    row-gap: 80px;
  }

  @media (width >= 1000px) {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width >= 1000px) {
  .support-modules--no-gap {
    grid-column-gap: 0;
    border-radius: var(--radius-large);
    background-color: var(--color-surface-solid-light-lighter);
  }
}

.support-module {
  position: relative;
  z-index: 2; /* stop dropdown rail going behind next component */
}
